import { get_from_localStorage, invokeApi } from "src/utils";
export const _subscribe_plan = (data, token) => {
  const requestObj = {
    path: `api/subscription/buy_subscription_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _complete_3d_payment = (data, token) => {
  const requestObj = {
    path: `api/subscription/confirm_incomplete_subscription`,
    method: "POST",
    headers: {
      "x-sh-auth": token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _subscribtion_list = (token) => {
  const requestObj = {
    path: `api/subscription/get_subscription_list`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};
export const _subscribtion_history = () => {
  const requestObj = {
    path: `api/subscription_history/get_subscription_history_list`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _cancel_subscription = () => {
  const requestObj = {
    path: `api/subscription/cancle_subscription_plan`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_card = (data) => {
  const requestObj = {
    path: `api/subscription/change_card`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
