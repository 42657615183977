import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  TextField,
  Box,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  ListItem,
  CardContent,
  List,
  ListItemButton,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  Iconify,
  MuiDialog,
  NavBackHeader,
  Page,
  RichEditor,
} from "src/components";
import { useSnackbar } from "notistack";
import { ALLOWED_IMAGE_TYPES } from "src/constants";
import { upload_image } from "src/utils";
import { _create_user_workspace } from "src/DAL";
import { useNavigate } from "react-router-dom";
import ImageSelector from "src/components/ImageSelector";
import { useAppContext } from "src/hooks";
import { CUSTOM_TASK_STATUS_COLOR } from "src/constants/constants";
// ---------------------------------------------------------
const common_button_size = "5rem";
// ---------------------------------------------------------
const CreateWorkspace = () => {
  const [formInputs, setFormInputs] = useState({
    name: "",
    description: "",
    image: "",
  });
  const [newStatus, setNewStatus] = useState({
    title: "",
    color: "",
  });
  const [editStatus, setEditStatus] = useState();
  const [editIndex, setEditIndex] = useState();
  const [addStatusDialogOpen, setAddStatusDialogOpen] = useState(false);
  const [editStatusDialogOpen, setEditStatusDialogOpen] = useState(false);
  const closeNewStatusDialog = () => {
    setAddStatusDialogOpen(false);
    setNewStatus({
      title: "",
      color: "",
    });
  };
  const closeEditStatusDialog = () => {
    setEditStatusDialogOpen(false);
    setEditStatus();
    setEditIndex();
  };
  const [customStatus, setCustomStatus] = useState([]);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const {
    setLimitReachedDialogOpen,
    dispatch_get_plan_limits,
    dispatch_set_plan_limits,
    dispatch_get_current_plan,
  } = useAppContext();
  // ----------------------------------
  const addStatus = () => {
    if (!newStatus.title) {
      enqueueSnackbar("Please add title for the status", { variant: "error" });
      return;
    }
    if (!newStatus.color) {
      enqueueSnackbar("Please choose color for the status", {
        variant: "error",
      });
      return;
    }
    let found = customStatus.find(
      (status) => status.title == newStatus.title.trim()
    );
    if (found) {
      enqueueSnackbar("Name should be unique", { variant: "error" });
      return;
    }
    setCustomStatus((prev) => [
      ...prev,
      {
        color_code: newStatus.color.color_code,
        title: newStatus.title,
        is_custom: true,
        is_default: false,
        color_id: newStatus.color.color_id,
        value: newStatus.color.value,
        color: newStatus.color,
      },
    ]);
    closeNewStatusDialog();
  };
  const deleteFromCustomStatus = (index) => {
    customStatus.splice(index, 1);
    setCustomStatus([...customStatus]);
  };
  const editFromCustomStatus = (index) => {
    setEditStatus({
      ...customStatus[index],
    });
    setEditStatusDialogOpen(true);
    setEditIndex(index);
  };
  const handleEditStatus = () => {
    if (!editStatus.title) {
      enqueueSnackbar("Please add title for the status", { variant: "error" });
      return;
    }
    let found = customStatus.find(
      (status) => status.title == editStatus.title.trim()
    );
    if (found) {
      enqueueSnackbar("Name should be unique", { variant: "error" });
      return;
    }
    let temp = customStatus;
    temp[editIndex] = {
      color_code: editStatus.color.color_code,
      title: editStatus.title,
      is_custom: true,
      is_default: false,
      color_id: editStatus.color.color_id,
      value: editStatus.value,
      color: editStatus.color,
    };
    setCustomStatus(temp);
    closeEditStatusDialog();
  };
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const getImgSrc = (path) => {
    return path;
  };
  const handleFileChange = async (e) => {
    if (loading) {
      return;
    }
    let file = e.target.files[0];
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setFormInputs({ ...formInputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      dispatch_get_plan_limits().workspace_count >=
      dispatch_get_current_plan().workspace_allow
    ) {
      setLimitReachedDialogOpen(true);
      return;
    }
    setLoading(true);
    const req_data = {
      name: formInputs.name,
      description: formInputs.description,
      image: "",
      extra_status: customStatus.map((status) => {
        let temp = status;
        // delete temp.color;
        return temp;
      }),
    };
    if (file) {
      const result = await upload_image(file);
      if (result.code === 200) {
        req_data.image = result.path;
      }
    }

    const result = await _create_user_workspace(req_data);
    if (result.code === 200) {
      setLoading(false);
      enqueueSnackbar("Workspace created successfully", { variant: "success" });
      // navigate(-1);
      navigate(`/workspaces/${result.workspace._id}`, {
        state: { workspace_data: { ...result.workspace, role: "admin" } },
      });
      dispatch_set_plan_limits({
        ...dispatch_get_plan_limits(),
        workspace_count: dispatch_get_plan_limits().workspace_count + 1,
      });
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------
  return (
    <Page title="Create Workspace">
      <Container maxWidth="xl">
        <div className="mb-4">
          <NavBackHeader heading="Create Your Workspace" />
        </div>
        <Container maxWidth="sm" sx={{ minHeight: "82.8vh" }}>
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <div>
                <TextField
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  autoFocus
                />
              </div>
              <ImageSelector
                setFile={setFile}
                placeHolder="Choose or Drop Image (optional). Recommended aspect ratio 1:1"
              />
              {/* <div className="d-flex justify-content-between align-items-center">
                <Typography variant="subtitle" fontWeight={"bold"}>
                  Custom Task Status
                </Typography>
                {customStatus.length < 5 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setAddStatusDialogOpen(true)}
                  >
                    Add status
                  </Button>
                )}
              </div>
              {customStatus != 0 && (
                <div>
                  <Container>
                    <Card>
                      <CardContent>
                        <List>
                          {customStatus.map((status, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemButton>
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          backgroundColor: status.color_code,
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                        }}
                                      />
                                      <Typography variant="subtitle">
                                        {status.title}
                                      </Typography>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      <IconButton
                                        onClick={() =>
                                          editFromCustomStatus(index)
                                        }
                                      >
                                        <Iconify icon="iconamoon:edit-bold" />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          deleteFromCustomStatus(index)
                                        }
                                      >
                                        <Iconify icon="ic:outline-delete" />
                                      </IconButton>
                                    </div>
                                  </div>
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      </CardContent>
                    </Card>
                  </Container>
                </div>
              )} */}
              <div>
                <RichEditor
                  editorRef={editorRef}
                  value={formInputs.description}
                  setValue={(val) =>
                    setFormInputs({
                      ...formInputs,
                      description: val,
                    })
                  }
                />
              </div>

              <div className="centered-row">
                <LoadingButton
                  sx={{ width: common_button_size }}
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Create
                </LoadingButton>
              </div>
            </Stack>
          </Box>
        </Container>
      </Container>
      <MuiDialog
        open={addStatusDialogOpen}
        onToggle={closeNewStatusDialog}
        title="Add Custom Status"
        message="Add a custom status for your tasks"
        onAgree={addStatus}
        ExtraComponent={
          <>
            <TextField
              name="title"
              value={newStatus.title}
              onChange={(e) =>
                setNewStatus((prev) => {
                  return { ...prev, title: e.target.value };
                })
              }
              label="Status title"
            />
            <FormControl fullWidth>
              <InputLabel id="colorSelector">Select Color</InputLabel>
              <Select
                value={newStatus.color}
                label="Select Color"
                id="colorSelector"
                onChange={(e) =>
                  setNewStatus((prev) => {
                    return { ...prev, color: e.target.value };
                  })
                }
              >
                {CUSTOM_TASK_STATUS_COLOR.filter(
                  (obj1) =>
                    !customStatus.some(
                      (obj2) => obj2.color_code === obj1.color_code
                    )
                ).map((color, index) => {
                  return (
                    <MenuItem key={index} value={color}>
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            height: "15px",
                            width: "15px",
                            backgroundColor: color.color_code,
                          }}
                        />
                        <Typography sx={{ ml: 2 }}>
                          {color.color_name}
                        </Typography>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        }
      />
      <MuiDialog
        open={editStatusDialogOpen}
        onToggle={closeEditStatusDialog}
        onAgree={handleEditStatus}
        title="Edit Status"
        message="Do you want to edit this status"
        ExtraComponent={
          editStatus && (
            <>
              <TextField
                name="title"
                value={editStatus.title}
                onChange={(e) =>
                  setEditStatus((prev) => {
                    return { ...prev, title: e.target.value };
                  })
                }
                label="Status title"
              />
              <FormControl fullWidth>
                <InputLabel id="colorSelector">Select Color</InputLabel>
                <Select
                  value={editStatus.color}
                  label="Select Color"
                  id="colorSelector"
                  onChange={(e) =>
                    setEditStatus((prev) => {
                      return { ...prev, color: e.target.value };
                    })
                  }
                >
                  {CUSTOM_TASK_STATUS_COLOR.filter(
                    (obj1) =>
                      !customStatus.some(
                        (obj2) =>
                          obj2.color_code === obj1.color_code &&
                          obj1.color_code !== editStatus.color_code
                      )
                  ).map((color, index) => {
                    return (
                      <MenuItem key={index} value={color}>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              height: "15px",
                              width: "15px",
                              backgroundColor: color.color_code,
                            }}
                          />
                          <Typography sx={{ ml: 2 }}>
                            {color.color_name}
                          </Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )
        }
      />
    </Page>
  );
};

export default CreateWorkspace;
