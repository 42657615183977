import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../Iconify";
import { MuiDialog } from "..";
import { isColorLight } from "src/utils";
import {
  _add_task_tag_in_project,
  _delete_task_tag_in_project,
  _edit_task_tag_in_project,
  _update_task_tags_in_task,
} from "src/DAL";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

function LabelPopup({
  projectId,
  projectTags = [],
  setProjectTags,
  task,
  onChangeTags,
  sections,
  getFilteredTasksBySection,
  getListing = null,
  buttonOpens = null,
  setButtonOpens = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addOrEditDialogOpen, setAddOrEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [targetTag, setTargetTag] = useState();
  const [actionLoading, setActionLoading] = useState(false);
  const [tagState, setTagState] = useState({
    name: "",
    color_code: "#00ab55",
    type: "Add",
  });
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const openDeleteDialog = (tag) => {
    setTargetTag(tag);
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setTargetTag();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTagState((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleChangeChecked = async (tag) => {
    let found = task.tag_colors.find((temp) => temp.id == tag.id);
    let req_obj;
    if (found) {
      req_obj = {
        task_id: task._id,
        tag_color_list: task.tag_colors.filter((temp) => temp.id != tag.id),
      };
    } else {
      req_obj = {
        task_id: task._id,
        tag_color_list: [...task.tag_colors, tag],
      };
    }
    onChangeTags(req_obj);
  };
  const openAddOrEditDialog = () => {
    setAddOrEditDialogOpen(true);
  };
  const closeAddOrEditDialog = () => {
    setAddOrEditDialogOpen(false);
    setTagState({
      title: "",
      color_code: "#00ab55",
      type: "Add",
    });
  };
  const onEditTag = (tag) => {
    setTagState({ ...tag, type: "Edit" });
    openAddOrEditDialog();
  };
  const handleAction = async (type) => {
    try {
      setActionLoading(true);
      let result;
      if (type == "Add") {
        const req_obj = {
          project_id: projectId,
          name: tagState.name,
          color: tagState.color_code,
        };
        result = await _add_task_tag_in_project(req_obj);
      } else if (type == "Edit") {
        const req_obj = {
          project_id: projectId,
          name: tagState.name,
          color: tagState.color_code,
          old_tag_id: String(tagState.id),
        };
        result = await _edit_task_tag_in_project(req_obj);
      } else {
        const req_obj = {
          project_id: projectId,
          old_tag_id: String(targetTag.id),
        };
        result = await _delete_task_tag_in_project(req_obj);
      }
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setProjectTags(result.project_tag_color.tag_colors);
        if (!getListing) {
          sections.section_lists.map((section) => {
            if (section.expanded) {
              getFilteredTasksBySection(section._id);
            }
          });
        } else {
          getListing();
        }
        closeAddOrEditDialog();
        closeDeleteDialog();
      }
    } catch (error) {
      console.log(error, ` catched while ${type}ing tag`);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      setActionLoading(false);
    }
  };
  return (
    <div>
      <Tooltip title="Choose Tag">
        <IconButton onClick={openDialog}>
          <Iconify icon="material-symbols:label-sharp" />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="xs">
        <DialogTitle sx={{ py: 1 }}>
          <div className="d-flex justify-content-between align-items-center">
            <div></div>
            <div className="d-flex">
              Task Tags
              <Tooltip title="Pick a color for the tag, type its name, and check the box to attach it to task">
                <div>
                  <Iconify sx={{ ml: 1 }} icon="ic:sharp-info" />
                </div>
              </Tooltip>
            </div>
            {/* <Tooltip title="Add Tag"> */}
            <IconButton onClick={closeDialog}>
              <Iconify icon="ic:round-close" />
            </IconButton>
            {/* </Tooltip> */}
          </div>
        </DialogTitle>
        {/* <Typography sx={{ px: 3 }}>{task.task_title}</Typography> */}
        <DialogContent>
          <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
            {projectTags.map((tag) => (
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Checkbox
                  size="small"
                  checked={task.tag_colors.find((temp) => temp.id == tag.id)}
                  onChange={() => handleChangeChecked(tag)}
                />
                <Tooltip title={tag.name}>
                  <div
                    style={{
                      backgroundColor: tag.color_code,
                      borderRadius: "5px",
                      minWidth: "200px",
                      maxWidth: "200px",
                      minHeight: "35px",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      color: isColorLight(tag.color_code) ? "#000" : "#fff",
                    }}
                    className="px-2 py-1"
                  >
                    {tag.name}
                  </div>
                </Tooltip>
                <Tooltip title="Update tag">
                  <div>
                    <IconButton onClick={() => onEditTag(tag)}>
                      <Iconify
                        icon={"ic:baseline-edit"}
                        sx={{ height: "15px", width: "15px" }}
                      />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title="Delete tag">
                  <div>
                    <IconButton onClick={() => openDeleteDialog(tag)}>
                      <Iconify
                        icon={"ic:baseline-delete"}
                        sx={{ height: "15px", width: "15px" }}
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions className="justify-content-center">
          <Button variant="contained" onClick={openAddOrEditDialog}>
            New Tag
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addOrEditDialogOpen} onClose={() => {}}>
        <DialogTitle>{tagState.type} tag</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ pt: 1 }}>
            <TextField
              label="Tag name"
              type="text"
              name="name"
              value={tagState.name}
              onChange={handleChange}
            />
            <TextField
              label="Color"
              type="color"
              name="color_code"
              value={tagState.color_code}
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddOrEditDialog}>Cancel</Button>
          <LoadingButton
            loading={actionLoading}
            onClick={() => handleAction(tagState.type)}
          >
            {tagState.type == "Add" ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <MuiDialog
        loading={actionLoading}
        title="Delete Tag"
        message="Are you sure you want to delete this tag?"
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        onAgree={() => handleAction("delete")}
      />
    </div>
  );
}

export default LabelPopup;
